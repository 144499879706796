import React, { useState, useEffect } from 'react'

import axios from 'axios'

import logo from '../../assets/logo.png'

import ImageFullSize from '../../Components/imageFullSize/ImageFullSize';

export default function Gallery() {
    const [fullSizeImgId, setfullSizeImg] = useState()
    const [images, setImages] = useState()

    useEffect(() => {
        axios.get('https://api.restauracjapodjablonia.pl/images')
            .then(({ data: { images } }) => {
                const sortedImages = images.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) })
                setImages(sortedImages)
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const zoom = (e, index) => {
        e.stopPropagation();
        if (!images || !images.length) return;
        setfullSizeImg(index)
    }

    const prev = (e) => {
        e.stopPropagation();
        setfullSizeImg(p => {
            if (p === 0) return images.length - 1
            return --p
        })
    }

    const returnImg = (index) => {
        const { formats: { large: { url } } } = images[index]
        return url
    }

    const next = (e) => {
        e.stopPropagation();
        setfullSizeImg(p => {
            if (p === images.length - 1) return 0
            return ++p
        })
    }

    const close = () => {
        setfullSizeImg(null)
    }

    if (!images || !images.length) return (<div>loading data</div>)

    return (

        <section className="gallery">
            <div className="gallery__desktop">
                <h2 className="gallery__title">Galeria</h2>
                <div className="gallery__container">
                    {images.map(({ id, formats: { small: { url } } }, index) => (
                        <div onClick={(event) => zoom(event, index)} key={id} className="image">
                            <img loading="lazy" src={`https://api.restauracjapodjablonia.pl${url}`} alt={'alt'} />
                        </div>
                    ))}
                </div>
                <div className="gallery__imageContainer">
                    <img className="gallery__image" src={logo} alt="logo" />
                </div>
            </div>
            {fullSizeImgId || fullSizeImgId === 0 ? <ImageFullSize prev={prev} next={next} close={close} src={returnImg(fullSizeImgId)} /> : null}
        </section>
    )
}
// const images = [
//     '_DSC0011.jpg', '_DSC0018.jpg', '_DSC0027.jpg', '_DSC0034.jpg',
//     '_DSC0023.jpg', '_DSC0031.jpg', '_DSC0053.jpg', '_DSC0277.jpg',
//     '_DSC0217.jpg', '_DSC0241.jpg', '_DSC0160.jpg', '_DSC0280.jpg',
//     '_DSC0207.jpg', '_DSC0182.jpg', '_DSC0185.jpg', '_DSC0096.jpg',
//     '_DSC0360-36.jpg', '123.jpg', '_DSC1163-HDR.jpg', '_DSC1110.jpg',
//     '_DSC1031.jpg', '_DSC1039.jpg', '_DSC1050.jpg', '_DSC1022.jpg',
//     '_DSC1116.jpg', '_DSC6886.jpg', '_DSC6898.jpg', '_DSC6884.jpg',
//     '_DSC0136.jpg', '_DSC5647.jpg', '_DSC0067.jpg', '_DSC5678.jpg'
// ];