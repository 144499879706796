import React from "react";

const menuList = [
  "/menu1-1.png",
  "/menu1-2.png",
  "/menu2-1.png",
  "/menu2-2.png",
  "/menu3-1.png",
  "/menu3-2.png",
];
export default function Menu() {
  return (
    <div className="menu" style={{ paddingTop: "16rem" }}>
      {menuList.map((menu) => (
        <div>
          <img style={{ width: "100%", height: "100%" }} src={menu} alt="" />
        </div>
      ))}
    </div>
  );
}
