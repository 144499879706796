import React from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Contact from "./Views/Contact";
import CookieConsent from "react-cookie-consent";
import Events from "./Views/Events";
import Gallery from "./Views/Gallery";
import Home from "./Views/Home";
import Nav from "./Components/Nav";
import Menu from "./Views/Menu";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/menu">
            <Menu />
          </Route>
          <Route path="/wydarzenia">
            <Events />
          </Route>
          <Route path="/galeria">
            <Gallery />
          </Route>
          <Route path="/kontakt">
            <Contact />
          </Route>

          <Route path="*">
            <Redirect to={""} />
          </Route>
        </Switch>
      </div>
      <CookieConsent
        buttonText="Zgadzam się"
        buttonStyle={{
          backgroundColor: "#AC554B",
          color: "#fff",
          fontSize: "13px",
        }}
      >
        Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach
        statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim możemy
        indywidualnie dostosować stronę do twoich potrzeb. Każdy może
        zaakceptować pliki cookies albo ma możliwość wyłączenia ich w
        przeglądarce, dzięki czemu nie będą zbierane żadne informacje.
      </CookieConsent>
    </Router>
  );
}

export default App;
