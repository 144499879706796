import React, { useState, useEffect } from "react";
import Icons from "../Icons/Icons";
import logo from "../../assets/newLogo.png";
import { Link } from "react-router-dom";
import { debounce } from "../../utils/helpers";
import { useRouteMatch } from "react-router-dom";
const items = [
  {
    text: "Menu",
    src: "menu",
  },
  {
    text: "Wydarzenia",
    src: "wydarzenia",
  },
  {
    text: "Galeria",
    src: "galeria",
  },
  {
    text: "Kontakt",
    src: "kontakt",
  },
];
export default function Nav() {
  let match = useRouteMatch("/na-wynos");
  const [isOpen, toggle] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 50);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (match) window.removeEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll, match]);

  return (
    <header
      className={
        visible ? "header__container" : "header__container bottomScroll"
      }
    >
      <div className="header">
        <Link className="header__logo" to="/">
          <figure className="header__logoImgContainer">
            <img className="header__logoImg" src={logo} alt="logo" />
          </figure>
          <h1>pod jabłonią</h1>
        </Link>

        <div
          onClick={() => toggle(!isOpen)}
          className={isOpen ? `header__icon open` : `header__icon`}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav
          className={
            isOpen ? `header__navigation navOpen` : `header__navigation`
          }
        >
          <ul className={isOpen ? `header__list ` : `header__list close`}>
            {items.map((item, i) => (
              <li
                onClick={() => toggle(false)}
                className="header__listItem"
                key={i}
              >
                <Link style={item.style} to={item.src}>
                  {item.text}
                </Link>
              </li>
            ))}

            <li className="header__listFooter">
              <div className="header__socials">
                <a
                  aria-label="Facebook"
                  href="https://www.facebook.com/restauracjapodjablonia/"
                >
                  <Icons iconName="facebook" />
                </a>
                <a
                  aria-label="Instagram"
                  href="https://www.instagram.com/restauracja_podjablonia/"
                >
                  <Icons iconName="instagram" />
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
